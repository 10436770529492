import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import arrowImage from './media/arrow.svg';

import styles from './BackButton.module.scss';

const BackButton: FC<IProps> = (props) => {
    return (
        <button
            className={classNames(styles.backButton, props.className)}
            onClick={props.onClick}
        >
            <img
                className={styles.image}
                src={arrowImage}
                alt={''}
            />
            {props.children}
        </button>
    );
};

export default BackButton;
