import {
    IItem,
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useCallback,
    useMemo,
} from 'react';

import {
    useIsMobile,
} from 'helpers/hooks';

import Loading from '../Loading';

import EmptyResult from './EmptyResult';
import DataCell from './Row/DataCell';

import {
    normalizeColumnSpan,
} from './functions';

import styles from './EntityTable.module.scss';

const EntityTable: FC<IProps<IItem>> = (props) => {
    const isMobile = useIsMobile();

    const totalColSpan = useMemo(() => {
        return Object
            .values(props.columnsEnum)
            .reduce((res, column) => {
                return res + normalizeColumnSpan(column, props.formatColumnSpan);
            }, 1);
    }, [props.columnsEnum]);
    const Row = useCallback((newProps: any) => {
        return (<props.row {...newProps}/>);
    }, []);

    const formatColumnDisplay = (column: string): boolean => {
        if (!props.columns) {
            return true;
        }

        return props.columns.includes(column);
    };

    return (
        <div className={classNames(styles.entityTable, props.className)}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {[
                            ...Object
                                .values(props.columnsEnum)
                                .map((column) =>
                                    formatColumnDisplay(column) &&
                                    <DataCell
                                        key={column}
                                        type={'th'}
                                        className={classNames(styles.cell, styles.headCell)}
                                        colSpan={normalizeColumnSpan(column, props.formatColumnSpan)}
                                        colWidth={props.formatColumnWidth?.(column, isMobile) ?? null}
                                    >
                                        {props.formatColumnTitle(column)}
                                    </DataCell>
                                ),
                            <th key={'options'}/>,
                        ]}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.items ?
                            props.items.length ?
                                props.items.map((item, rowIndex) => {
                                    return (
                                        <Row
                                            key={item.id}
                                            rowIndex={rowIndex}
                                            item={item}
                                            columnsEnum={props.columnsEnum}
                                            cellClassName={styles.cell}
                                            formatColumnSpan={props.formatColumnSpan}
                                            formatColumnDisplay={formatColumnDisplay}
                                        />
                                    );
                                }) :
                                <tr>
                                    <td colSpan={totalColSpan}>
                                        <EmptyResult/>
                                    </td>
                                </tr> :
                            <tr>
                                <td colSpan={totalColSpan}>
                                    <Loading className={styles.loading}/>
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    );
};

export default EntityTable;
