import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

import DateTime from 'helpers/DateTime';

import styles from './DateTimeDisplay.module.scss';

const DateTimeDisplay: FC<IProps> = (props) => {
    return (
        <span className={styles.dateTimeDisplay}>
            <span className={styles.date}>
                {DateTime.formatDate(props.value)}
            </span>
            &nbsp;
            <span className={styles.time}>
                {DateTime.formatTime(props.value)}
            </span>
        </span>
    );
};

export default DateTimeDisplay;
