import {
    useParams,
} from 'react-router-dom';

type IParseIdType = 'number' | 'string';
type IEnum<IValue> = Record<string, IValue>;
type IGetIdTypeByParseIdType<IIdType extends IParseIdType> =
    IIdType extends 'number' ? number | null :
        IIdType extends 'string' ? string | null :
            null;

type IParams = {
    actionPath: string;
    id: string;
};

interface IParsedParams<IActionPath, IIdType extends IParseIdType> {
    actionPath: IActionPath | null;
    id: IGetIdTypeByParseIdType<IIdType>;
}

export function isActionPath<IActionPath>(actionPath: any, actionPathEnum: IEnum<IActionPath>): actionPath is IActionPath {
    return Object.values(actionPathEnum).includes(actionPath ?? '');
}

export function parseId<IIdType extends IParseIdType>(id: string | undefined, type: IIdType): IGetIdTypeByParseIdType<IIdType> {
    switch (type) {
        case 'number': {
            const res = parseInt(id ?? '');

            return (isNaN(res) ? null : res) as IGetIdTypeByParseIdType<IIdType>;
        }
        case 'string': {
            return (id || null) as IGetIdTypeByParseIdType<IIdType>;
        }
        default: {
            return null as IGetIdTypeByParseIdType<IIdType>;
        }
    }
}

export default function<IActionPath, IIdType extends IParseIdType>(actionPathEnum: IEnum<IActionPath>, type: IIdType): IParsedParams<IActionPath, IIdType> {
    const {actionPath, id} = useParams<IParams>();

    return {
        actionPath: isActionPath<IActionPath>(actionPath, actionPathEnum) ? actionPath : null,
        id: parseId<IIdType>(id, type),
    };
}
