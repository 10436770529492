import {
    IIcon,
} from './types';

import plusImage from './media/plus.svg';

export function mapIconSrc(icon: IIcon): string {
    switch (icon) {
        case 'plus': {
            return plusImage;
        }
    }
}
