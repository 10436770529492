import {
    IAgentUser,
} from './types';
import {
    IPatchBySuperAdminBody,
    IPatchByUserBody,
    IPostBySuperAdminBody,
} from './types/requests';
import {
    IGetByUser,
    IGetOneByUser,
    IPatchBySuperAdmin,
    IPatchByUser,
    IPostBySuperAdmin,
} from './types/responses';

import {
    useState,
} from 'react';
import {
    atom,
    useRecoilState,
} from 'recoil';

import Entity from '../../helpers/Entity';

import * as fetches from './fetches';

const agentsAtom = atom<IAgentUser[] | null>({
    key: 'agentsAtom',
    default: null,
});

export function useAgentsByUser() {
    const [agents, setAgents] = useRecoilState(agentsAtom);

    const getAgents = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.agents || res.error) {
            console.log(res.error);

            return res;
        }

        setAgents(res.agents);

        return res;
    };
    const addToAgentTotalBalance = (agentId: number, amount: number): void => {
        setAgents((agents) => {
            if (!agents) {
                return agents;
            }

            const agent = Entity.findOneById(agentId, agents);

            if (!agent) {
                return agents;
            }

            return Entity.replace({
                ...agent,
                totalBalance: String(BigInt(agent.totalBalance) + BigInt(amount)),
            }, agents);
        });
    };

    return {
        agents,
        getAgents,
        addToAgentTotalBalance,
    };
}

export function useAgentByUser() {
    const [agent, setAgent] = useState<IAgentUser | null>(null);

    const getAgent = async (id: number): Promise<IGetOneByUser> => {
        const res = await fetches.getOneByUser(id);

        if (!res.agent || res.error) {
            console.log(res.error);

            return res;
        }

        setAgent(res.agent);

        return res;
    };
    const postAgentBySuperAdmin = async (body: IPostBySuperAdminBody): Promise<IPostBySuperAdmin> => {
        const res = await fetches.postBySuperAdmin(body);

        if (!res.agent || res.error) {
            console.log(res.error);

            return res;
        }

        setAgent(res.agent);

        return res;
    };
    const patchAgentBySuperAdmin = async (body: IPatchBySuperAdminBody): Promise<IPatchBySuperAdmin> => {
        const res = await fetches.patchBySuperAdmin(body);

        if (!res.agent || res.error) {
            console.log(res.error);

            return res;
        }

        setAgent(res.agent);

        return res;
    };
    const patchAgentByUser = async (body: IPatchByUserBody): Promise<IPatchByUser> => {
        const res = await fetches.patchByUser(body);

        if (!res.agent || res.error) {
            console.log(res.error);

            return res;
        }

        setAgent(res.agent);

        return res;
    };

    return {
        agent,
        getAgent,
        postAgentBySuperAdmin,
        patchAgentBySuperAdmin,
        patchAgentByUser,
    };
}
