import {
    IWithResponseError,
} from 'tools/types';

import {
    useEffect,
    useState,
} from 'react';

import {
    useFormPageParams,
} from '.';

type IParseIdType = 'number' | 'string';
type IEnum<IValue> = Record<string, IValue>;
type IGetIdTypeByParseIdType<IIdType extends IParseIdType> = IIdType extends 'number' ?
    number | null : IIdType extends 'string' ?
        string | null :
        null;

interface IResponse extends IWithResponseError {

}

interface ISettings<IActionPath, IIdType extends IParseIdType> {
    idType: IIdType;
    actionPathEnum: IEnum<IActionPath>;

    requestingActionPath?: IActionPath;

    getEntity(id: IGetIdTypeByParseIdType<IIdType>): Promise<IResponse>;
}

export default function<IActionPath extends string, IIdType extends IParseIdType>(settings: ISettings<IActionPath, IIdType>) {
    const [isPendingEntity, setIsPendingEntity] = useState(!!settings.requestingActionPath);
    const [isNotFoundEntity, setIsNotFoundEntity] = useState(false);

    const {
        id,
        actionPath,
    } = useFormPageParams<IActionPath, IIdType>(settings.actionPathEnum, settings.idType);

    useEffect(() => {
        (async () => {
            if (settings.requestingActionPath !== actionPath) {
                setIsPendingEntity(false);

                return;
            }
            if (!id) {
                setIsPendingEntity(false);
                setIsNotFoundEntity(true);

                return;
            }

            const res = await settings.getEntity(id);

            if (res.error) {
                setIsPendingEntity(false);
                setIsNotFoundEntity(true);

                return;
            }

            setIsPendingEntity(false);
        })();
    }, [id, actionPath]);

    return {
        id,
        actionPath: actionPath as IActionPath,
        isPendingEntity,
        isNotFoundEntity,
    };
}
