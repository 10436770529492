import {
    IItem,
} from '../types';
import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

import {
    DotsButton,
} from 'components/buttons';

import DataCell from './DataCell';

import {
    normalizeColumnSpan,
} from '../functions';

const Row: FC<IProps<IItem>> = (props) => {
    return (
        <tr className={props.className}>
            {[
                ...Object
                    .values(props.columnsEnum)
                    .map((column, columnIndex) =>
                        props.formatColumnDisplay(column) &&
                        <DataCell
                            key={column}
                            type={'td'}
                            className={props.cellClassName}
                            colSpan={normalizeColumnSpan(column, props.formatColumnSpan)}
                            colWidth={null} // Тут не надо задавать ширину, ибо достаточно только для шапки
                        >
                            {props.children?.[columnIndex]}
                        </DataCell>
                    ),
                <td key={'actions'}>
                    {
                        props.options &&
                        <DotsButton
                            options={
                                props.options.map((option) => {
                                    return {
                                        key: option.key,
                                        label: option.label,
                                        isHidden: option.isHidden,
                                        onClick: option.onClick,
                                    };
                                })
                            }
                        />
                    }
                </td>,
            ]}
        </tr>
    );
};

export default Row;
