import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import DateTimeDisplay from '../DateTimeDisplay';

import styles from './DoubleDateTimeDisplay.module.scss';

const DoubleDateTimeDisplay: FC<IProps> = (props) => {
    return (
        <span className={classNames(styles.doubleDateTimeDisplay, props.className)}>
            <DateTimeDisplay value={props.firstValue}/>
            /
            <DateTimeDisplay value={props.secondValue}/>
        </span>
    );
};

export default DoubleDateTimeDisplay;
