import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

export default function() {
    const [windowWidth, setWindowWidth] = useState(typeof window === 'undefined' ? 0 : window.innerWidth);

    const isMounted = useRef<boolean>(true);

    const handleResize = useCallback(() => {
        if (isMounted.current) {
            setWindowWidth(window.innerWidth);
        }
    }, [setWindowWidth]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            window.requestAnimationFrame(handleResize);
        });

        return () => {
            isMounted.current = false;
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return {
        windowWidth,
    };
}
