import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useMemo,
    useRef,
    useState,
} from 'react';

import styles from './DataCell.module.scss';

const DataCell: FC<IProps> = (props) => {
    const [hoveredDOMRect, setHoveredDOMRect] = useState<DOMRect | null>(null);

    const contentRef = useRef<HTMLDivElement>(null);

    const isOverflowed = useMemo<boolean>(() => {
        if (!contentRef.current) {
            return false;
        }

        return contentRef.current.clientWidth !== contentRef.current.scrollWidth;
    }, [contentRef.current]);

    const formatString = (value: string) => {
        return value
            .split('\n')
            .map((line, i) => (
                <div key={i}>
                    {line}
                </div>
            ));
    };

    return (
        <props.type
            className={classNames(styles.dataCell, props.className)}
            colSpan={props.colSpan}
            width={props.colWidth ?? undefined}
            onMouseEnter={() => setHoveredDOMRect(contentRef.current?.getBoundingClientRect() ?? null)}
            onMouseLeave={() => setHoveredDOMRect(null)}
        >
            <div
                ref={contentRef}
                className={styles.content}
            >
                {props.children}
            </div>
            {
                isOverflowed &&
                hoveredDOMRect &&
                <div
                    className={styles.tooltip}
                    style={{
                        top: hoveredDOMRect.top - 10,
                        left: hoveredDOMRect.left - 10,
                    }}
                >
                    {
                        typeof props.children === 'string' ?
                            formatString(props.children) :
                            props.children
                    }
                </div>
            }
        </props.type>
    );
};

export default DataCell;
