import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    useClickOutside,
} from 'tools/hooks';

import dotsImage from './media/dots.svg';

import styles from './DotsButton.module.scss';

const DotsButton: FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef<HTMLButtonElement>(null);

    const availableOptions = useMemo(() => {
        return props.options.filter((option) => !option.isHidden);
    }, [props.options]);

    useClickOutside(containerRef, () => {
        setIsOpen(false);
    });

    return (
        <button
            ref={containerRef}
            className={
                classNames(styles.dotsButton, props.className, {
                    [styles.isEmpty]: !availableOptions.length,
                })
            }
            onClick={() => setIsOpen(!isOpen)}
        >
            <img
                className={styles.image}
                src={dotsImage}
                alt={''}
            />
            {
                isOpen && !!availableOptions.length &&
                <div className={styles.options}>
                    {
                        props.options
                            .filter((option) => !option.isHidden)
                            .map(
                                (option) =>
                                    <div
                                        key={option.key}
                                        className={styles.option}
                                        onClick={option.onClick}
                                    >
                                        {option.label}
                                    </div>
                            )
                    }
                </div>
            }
        </button>
    );
};

export default DotsButton;
