import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import {
    mapIconSrc,
} from './functions';

import styles from './SimpleButton.module.scss';

const SimpleButton: FC<IProps> = (props) => {
    return (
        <button
            className={
                classNames(styles.simpleButton, props.className, {
                    [styles.isDisabled]: props.isDisabled,
                })
            }
            disabled={props.isDisabled}
            type={props.type ?? 'button'}
            onClick={props.onClick}
        >
            {
                props.icon &&
                <img
                    className={styles.icon}
                    src={mapIconSrc(props.icon)}
                    alt={''}
                />
            }
            {props.children}
        </button>
    );
};

export default SimpleButton;
