import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import arrowImage from './media/arrow.svg';

import styles from './ExportButton.module.scss';

const ExportButton: FC<IProps> = (props) => {
    if (props.href) {
        return (
            <a
                className={classNames(styles.exportButton, props.className)}
                href={props.href}
                download={true}
                onClick={(e) => !props.isDisabled && props.onClick?.(e)}
            >
                <img
                    src={arrowImage}
                    alt={''}
                />
                Export
            </a>
        );
    }

    return (
        <button
            className={classNames(styles.exportButton, props.className)}
            disabled={props.isDisabled}
            onClick={props.onClick}
        >
            <img
                src={arrowImage}
                alt={''}
            />
            Export
        </button>
    );
};

export default ExportButton;
