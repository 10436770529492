import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useEffect,
    useRef,
} from 'react';
import {
    createPortal,
} from 'react-dom';

import {
    useClickOutside,
} from 'tools/hooks';

import styles from './Modal.module.scss';

const Modal: FC<IProps> = (props) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!props.isOpen) {
            return;
        }

        function handleEsc(e: KeyboardEvent) {
            if (e.key !== 'Escape') {
                return;
            }

            props.onClose();
        }

        document.body.style.overflowY = 'hidden';
        window.addEventListener('keydown', handleEsc);

        return () => {
            document.body.style.overflowY = 'initial';
            window.removeEventListener('keydown', handleEsc);
        };
    }, [props.isOpen]);
    useClickOutside(modalRef, () => {
        props.onClose();
    });

    if (!props.isOpen) {
        return null;
    }

    return createPortal(
        <div className={styles.overlay}>
            <div
                ref={modalRef}
                className={classNames(styles.modal, props.className)}
            >
                {props.children}
            </div>
        </div>,
        document.body
    );
};

export default Modal;
